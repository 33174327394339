import React from "react"
import PropTypes from "prop-types"
import { Modal } from "antd"
const DeleteUserModal = ({ handleOk, isModalOpen, handleCancel, loading }) => {
	return (
		<Modal title="Delete User" onOk={handleOk} open={isModalOpen} onCancel={handleCancel} confirmLoading={loading}>
			<p>Are you sure you want to delete this user?</p>
		</Modal>
	)
}

DeleteUserModal.propTypes = {
	handleOk: PropTypes.func.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
}

export default DeleteUserModal
