import dayjs from "dayjs"
export const presets = [
	{
		label: "Year to Date",
		value: [dayjs().startOf("year"), dayjs()],
	},
	{
		label: "Last Month",
		value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")],
	},
	{
		label: "Month to Date",
		value: [dayjs().startOf("month"), dayjs()],
	},
	{
		label: "This Week",
		value: [dayjs().startOf("week"), dayjs()],
	},
	{
		label: "Today",
		value: [dayjs(), dayjs()],
	},
]
