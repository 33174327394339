import React from "react"
import PropTypes from "prop-types"

const CustomTableColumn = ({ text, digitsQuantity, customWidth }) => {
	let width

	if (digitsQuantity) {
		width = `${digitsQuantity * 10 + 5}px`
	}

	if (customWidth) width = customWidth

	return <div style={{ wordWrap: "break-word", wordBreak: "break-word", textAlign: "left", width }}>{text}</div>
}

CustomTableColumn.propTypes = {
	text: PropTypes.string.isRequired,
	digitsQuantity: PropTypes.number,
	customWidth: PropTypes.string,
}

export default CustomTableColumn
