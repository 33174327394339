import React from "react"
import PropTypes from "prop-types"
import { Modal, Text, Button } from "antd"

const ReInviteUserModal = ({ isOpen, handleCancel, onOk, loading }) => {
	const inviteUser = async (email) => {
		setIsReInviteLoading(true)
		const res = await API.reInviteUser(email)
		if (res.ok) {
			message.success("User invited")
		} else {
			message.error(res.error.message)
		}
		setIsReInviteLoading(false)
		closeReInviteModal()
	}

	return (
		<Modal onOk={onOk} open={isOpen} onCancel={handleCancel} title="Re-invite user" confirmLoading={loading}>
			<p>Are you sure you want to re-invite this user? They will receive an email</p>
		</Modal>
	)
}

ReInviteUserModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
}

export default ReInviteUserModal
