import React, { useState, createRef } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { Form, Button, message } from "antd"
import { PasswordForm } from "./"
import { API } from "../../../App"

const UpdatePasswordForm = observer(({ id }) => {
	const formRef = createRef()
	const navigate = useNavigate()
	const [password, setPassword] = useState("")
	const onFinish = async ({ password }) => {
		const response = await API.updatePassword(id, { password })
		if (response.error) {
			message.error(response.error)
			formRef.current.resetFields()
		}

		message.success("Password updated")
		navigate("/login")
	}
	return (
		<Form
			name="password-reset-form"
			onFinish={onFinish}
			ref={formRef}
			labelCol={{ span: 6 }}
			style={{ margin: "auto" }}
			wrapperCol={{ span: 16 }}
			autoComplete="off"
		>
			<PasswordForm password={password} setPassword={setPassword} />
			<Form.Item wrapperCol={{ offset: 18 }}>
				<Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
					submit
				</Button>
			</Form.Item>
		</Form>
	)
})

export default UpdatePasswordForm
