import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Progress, Tag } from "antd"

const OptionalTag = ({ criteria, text }) => {
	return <Tag color={!!criteria ? "success" : "default"}>{text}</Tag>
}

const PasswordStrength = ({ password, setPasswordIsValid }) => {
	const hasNumber = password.match(/\d/)
	const hasUpper = password.match(/[A-Z]/)
	const hasLower = password.match(/[a-z]/)
	const hasSpecial = password.match(/[!@#$%^&*(),.?":{}|<>]/)
	const isLong = password.length >= 8

	const score = !!hasNumber + !!hasUpper + !!hasLower + !!hasSpecial + !!isLong

	const color = {
		0: "#828282",
		1: "#EA1111",
		2: "#EA1111",
		3: "#F2C94C",
		4: "#219653",
	}

	const passwordColor = color[score]

	useEffect(() => {
		if (score === 5) {
			setPasswordIsValid(true)
		} else {
			setPasswordIsValid(false)
		}
	}, [score, setPasswordIsValid])

	return (
		<>
			<Form.Item label={`Password Strength`}>
				<Progress percent={score * 20} strokeColor={passwordColor} />
			</Form.Item>
			<Form.Item label={`Password Criteria`}>
				<OptionalTag criteria={!!isLong} text="8 characters" />
				<OptionalTag criteria={!!hasNumber} text="Number" />
				<OptionalTag criteria={!!hasUpper} text="Upper Case" />
				<OptionalTag criteria={!!hasLower} text="Lower Case" />
				<OptionalTag criteria={!!hasSpecial} text="Special Character" />
			</Form.Item>
		</>
	)
}

PasswordStrength.propTypes = {
	password: PropTypes.string.isRequired,
	setPasswordIsValid: PropTypes.func.isRequired,
}

OptionalTag.propTypes = {
	criteria: PropTypes.bool,
	text: PropTypes.string.isRequired,
}

export default PasswordStrength
