import { Progress, Card, Popover } from "antd"
import PropTypes from "prop-types"
import React from "react"

const _ = require("lodash")

import "./index.css"

const Top5Widget = (props) => {
	const baseFontSize = "0.75rem"
	return (
		<Card title={props.title} className="top-5-widget-card" size="default" style={{ ...props.style }}>
			{props.data.length > 0 && (
				<div
					className="top-5-widget-container"
					style={{
						display: "grid",
					}}
				>
					<div className="graph-container">
						<Progress
							type="circle"
							strokeWidth={10}
							percent={props.topPercent}
							width={80}
							strokeLinecap="square"
							style={{ color: props.color }}
							strokeColor={props.color}
						/>
					</div>

					<div
						className="title-container"
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							fontSize: baseFontSize,
							gap: "10px",
						}}
					>
						<span
							style={{
								textWrap: "wrap",
								overflowWrap: "break-word",
							}}
						>
							{props.topLabel ? props.topLabel : _.first(props.data).label}
						</span>
						{props.extraText && (
							<span
								style={{
									textWrap: "nowrap",
								}}
							>
								{props.extraText}
							</span>
						)}
					</div>

					<div
						className="statistic-container"
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							justifyContent: "flex-start",
							fontSize: baseFontSize,
							marginBottom: 5,
							width: "100%",
						}}
					>
						{_.map(props.data, (d, i) => (
							<div
								style={{
									width: "100%",
									gap: 0,
									display: "flex",
									flexDirection: "row",
									alignItems: "space-between",
									justifyContent: "space-between",
								}}
							>
								<Popover placement="top" title={""} content={d.label}>
									<span
										style={{
											cursor: "default",
											textWrap: "nowrap",
											paddingRight: 5,
											maxWidth: "122px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
										key={i}
									>
										{d.label}
									</span>
								</Popover>

								<span style={{ fontWeight: "bold" }} key={i}>
									{d.value.toLocaleString()}
								</span>
							</div>
						))}
					</div>
				</div>
			)}
		</Card>
	)
}

Top5Widget.propTypes = {
	color: PropTypes.string,
	data: PropTypes.array,
	extraText: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
	topLabel: PropTypes.string,
	topPercent: PropTypes.number,
}

export default Top5Widget
