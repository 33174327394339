import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Input } from "antd"
import { PasswordStrength } from "./"

const PasswordForm = ({ password, setPassword }) => {
	const [passwordIsValid, setPasswordIsValid] = useState(false)
	const form = Form.useFormInstance()

	useEffect(() => {
		form.validateFields(["password"])
	}, [passwordIsValid, form])

	return (
		<>
			<Form.Item
				label="New Password"
				name="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				rules={[
					{ required: true, message: "Please input your password!" },
					{
						validator: () => {
							if (passwordIsValid) {
								return Promise.resolve()
							}
							return Promise.reject(
								new Error(
									"Password must be at least 8 characters long and contain at least one number, one upper case letter, one lower case letter, and one special character"
								)
							)
						},
					},
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				label="Confirm Password"
				name="confirmPassword"
				rules={[
					{ required: true, message: "Please confirm your password!" },
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue("password") === value) {
								return Promise.resolve()
							}
							return Promise.reject(new Error("The two passwords that you entered do not match!"))
						},
					}),
				]}
			>
				<Input.Password />
			</Form.Item>
			<PasswordStrength password={password} setPasswordIsValid={setPasswordIsValid} />
		</>
	)
}

PasswordForm.propTypes = {
	password: PropTypes.string.isRequired,
	setPassword: PropTypes.func.isRequired,
}

export default PasswordForm
