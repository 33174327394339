export function sumCondensedLog(condensedLog, currentSlice) {
	condensedLog.opportunities += currentSlice.opportunities || 0
	condensedLog.flavorings += currentSlice.flavorings || 0
	condensedLog.flavor_dispense += currentSlice.flavor_dispense || 0
	condensedLog.recons_and_flavor_dispense += currentSlice.recons_and_flavor_dispense || 0
	condensedLog.recons_dispense += currentSlice.recons_dispense || 0

	for (const [key, value] of Object.entries(currentSlice.drugs)) {
		if (condensedLog[key]) condensedLog[key] += value
		else condensedLog[key] = value
	}

	for (const [key, value] of Object.entries(currentSlice.flavors)) {
		if (condensedLog[key]) condensedLog[key] += value
		else condensedLog[key] = value
	}

	return condensedLog
}
