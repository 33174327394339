import React from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"

const UserStatusTag = ({ status }) => {
	const statusColorMap = {
		ACTIVE: "green",
		INACTIVE: "red",
		INVITED: "orange",
	}
	return (
		<Tag color={statusColorMap[status.toUpperCase()]} key={status}>
			{status}
		</Tag>
	)
}

UserStatusTag.propTypes = {
	status: PropTypes.string.isRequired,
}

export default UserStatusTag
