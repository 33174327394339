import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Form, Input, Select, Button, message } from "antd"
import { BaseUserEditForm, PasswordForm } from "./"
import { API } from "../../../App"
import { useStore } from "../../../stores"
import { useNavigate } from "react-router-dom"

const UserInviteAcceptForm = ({ email }) => {
	const formRef = useRef(null)
	const navigate = useNavigate()

	const { authStore } = useStore()

	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)
	const onFinish = async (values) => {
		setLoading(true)
		const res = await API.updateUserInfo({ password, inviteToken: "", status: "Active", ...values })
		if (res.ok) {
			message.success("User created")
			await authStore.login(res.data.email, password)
			navigate("/")
		} else {
			message.error(res.error.message)
			formRef.current.resetFields()
		}
		setLoading(false)
	}

	return (
		<Form
			name="user-invite-form"
			onFinish={onFinish}
			labelCol={{ span: 6 }}
			style={{ margin: "auto", flex: 1, itemAlign: "center", justifyContent: "center" }}
			wrapperCol={{ span: 16 }}
			initialValues={{ email }}
		>
			<BaseUserEditForm emailDisabled={true} />
			<PasswordForm password={password} setPassword={setPassword} />
			<Form.Item wrapperCol={{ offset: 18 }}>
				<Button type="primary" htmlType="submit" style={{ marginTop: 20 }} loading={loading}>
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}

UserInviteAcceptForm.propTypes = {
	email: PropTypes.string.isRequired,
}

export default UserInviteAcceptForm
