import { API } from "../App"
import { makeObservable, observable, action } from "mobx"
import jwt_decode from "jwt-decode"

const clearSessionStorage = () => {
	sessionStorage.removeItem("authenticated")
	sessionStorage.removeItem("accessToken")
}

class AuthStore {
	inProgress = false
	authenticated = sessionStorage.getItem("authenticated") === "true"
	error = null
	token = sessionStorage.getItem("accessToken")
	constructor() {
		makeObservable(this, {
			inProgress: observable,
			authenticated: observable,
			error: observable,
			token: observable,
			login: action,
			logout: action,
		})
	}

	async login(email, password) {
		this.inProgress = true
		this.error = null
		this.token = null

		const loginResponse = await API.login({ email, password })
		if (loginResponse.ok) {
			this.token = loginResponse.data.token
			this.authenticated = true
			this.inProgress = false
			API.setAccessToken(this.token)
			sessionStorage.setItem("authenticated", "true")
			sessionStorage.setItem("accessToken", this.token)
		} else {
			this.inProgress = false
			this.error = loginResponse.data.error
		}

		return loginResponse.data
	}

	get user() {
		if (!this.token) return null
		return jwt_decode(this.token)
	}

	get isAdmin() {
		if (!this.user) return false
		return this.user.roles.filter((role) => role.name === "FlavorX Admin").length > 0
	}

	get userRoles() {
		const user = this.user
		if (!user) return null
		return user.roles.map((role) => role.name)
	}

	async logout() {
		this.authenticated = false
		this.token = null
		clearSessionStorage()
	}
}

export default AuthStore
