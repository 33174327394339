import { Form, Card, Input, Button, message } from "antd"
import React, { useRef } from "react"
import { ScreenLayout } from "../components"
import { API } from "../App"
import { useNavigate } from "react-router"

const ResetPasswordScreen = () => {
	const formRef = useRef(null)
	const navigate = useNavigate()
	const onFinish = async (values) => {
		const { data } = await API.resetPassword({ email: values.email })
		if (data && data.error) {
			message.error(data.error.message)
		} else {
			message.success(`Password reset email sent to ${values.email}.`)
			navigate("/login")
		}

		formRef.current.resetFields()
	}
	return (
		<>
			<ScreenLayout hideNav>
				<Card title="Reset Password" bordered={false} style={{ maxWidth: 500, margin: "0 auto", marginTop: 40 }}>
					<Form
						name="reset-password"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						className="reset-password-form"
						layout="vertical"
						ref={formRef}
						style={{ padding: 40 }}
					>
						<Form.Item
							label="Email Address"
							name="email"
							hasFeedback
							rules={[
								{
									type: "email",
									message: "This is not a valid Email Address.",
								},
								{
									required: true,
									message: "A valid email address is required.",
								},
							]}
						>
							<Input autoComplete="email" />
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="reset-password-form-button"
								style={{
									width: "100%",
								}}
							>
								Reset Password
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</ScreenLayout>
		</>
	)
}

export default ResetPasswordScreen
