import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, Modal, Input, Dropdown, Select, message } from "antd"
import { API } from "../../App"

const InviteUserModal = ({ isModalOpen, handleCancel, setUsers }) => {
	const [roles, setRoles] = useState([])
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async () => {
		setLoading(true)
		try {
			const { email, role } = await form.validateFields()
			const res = await API.inviteUser({ email, roleId: role })
			if (res.ok) {
				message.success("User invited")
				setUsers(res.data)
				form.resetFields()
				handleCancel()
			} else {
				message.error(res.data.error.message)
				return
			}
		} catch (e) {
			message.error(e.message)
		}
		setLoading(false)
	}

	useEffect(() => {
		const fetchRoles = async () => {
			const { data } = await API.getRoles()
			setRoles(data)
		}
		fetchRoles()
	}, [])

	return (
		<Modal title="Invite User" onOk={onFinish} open={isModalOpen} onCancel={handleCancel} confirmLoading={loading}>
			<Form name="invite-user" form={form}>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							message: "Please input an email!",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Role"
					name="role"
					rules={[
						{
							required: true,
							message: "Please input a role!",
						},
					]}
				>
					<Select>
						{roles.map(({ name, id }) => {
							if (name !== "Customer")
								return (
									<Select.Option key={id} value={id}>
										{name}
									</Select.Option>
								)
						})}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}

InviteUserModal.propTypes = {
	isModalOpen: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	setUsers: PropTypes.func.isRequired,
}

export default InviteUserModal
